import React from "react";
import "./GlobalFooter.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const GlobalFooter = (props) => {
  const [footer, setFooter] = useState();
  const data = useSelector((state) => state.headerFooterData);

  //loading footer css and js files
  useEffect(() => {
    if (data) {
      setTimeout(function () {
        //your code to be executed after 2 second
        setFooter(data.footer);
      }, 200);
    }
  }, [data]);

  return (
    <div id="myAeroFooterDvNew">
      <div dangerouslySetInnerHTML={{ __html: footer }} />
    </div>
  );
};

export default GlobalFooter;
