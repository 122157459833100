import React from "react";
import "./envisionYourImpact.css";
import envisionGif from '../../../../assets/image/envisionYourImpactGif.png'
// import {GetRequest} from '../../services/services'
import CalculatorForm from "./CalculatorForm/Index";
import ArrowImage from '../../../../assets/image/Desktop/down-wt.png'

const EnvisionYourImpact = (props) => {
  const { loadSlider } = props
  return (
    <div className={"mainContainer_envision " + (!loadSlider ? 'without-slider-home-page' : '')}>
      <div className="body container-fluid" >
        <div className="row" style={{ paddingLeft: "3%" }}>
          <div className="col" style={{ textAlign: "left", lineHeight: "initial", height: "100%" }}>
            <h1 style={{ color: "#dc202e", fontWeight: "bold", fontSize: "58px" }}>ENVISION</h1>
            <h1 style={{ fontWeight: "bold", fontSize: "58px" }}>YOUR IMPACT</h1>

            <div className="row pt-1">
              <div className="col midtxt" style={{
                maxWidth: "38%", paddingTop: "2.1vh",
                fontSize: "2vw",
                letterSpacing: "0px",
                color: "#FFFFFF",
                opacity: 1
              }}>
                <a >Honeywell</a><br />
                <a >Aclar® Film Vs.</a><br />
                <a >Cold Form Foil</a>
              </div>
              <div className="col-4">
                <img src={envisionGif} alt="Gif" style={{ height: "10vw" }} className="screen1gif" />
              </div>
            </div>

            <p className="pt-1 pb-3" style={{ fontSize: "1.2vw", letterSpacing: "1px" }}>
              Did you know <strong>Aclar®</strong> is a more efficient choice<br />
              than traditional Cold Form Foil (CFF)?<br />
              <strong>Aclar®</strong> film not only delivers a high moisture<br />

              barrier to protect medicines, but it also  <span style={{ fontWeight: "bold" }}>reduces<br />
                pack size, reduces waste, and significantly<br />reduces carbon emissions.
              </span>*</p>

            <p style={{ fontSize: "1.2vw" }}>Input your options and see how <strong>Aclar®</strong> compares.</p>

            <p style={{ fontSize: "1vw" }}>* Compared to the same product packaged in CFF.</p>
          </div>

          <CalculatorForm {...props} />
        </div>
      </div>
      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="circle-arrow">   
          <img src={ArrowImage} alt="down arrow"/>
      </div>
    </div>
  )
}

export default EnvisionYourImpact

