import { render } from "@testing-library/react";
import React, { Component } from "react";
import "./moreReason.css";
import ArrowImage from '../../../../assets/image/Desktop/down-wt.png'


function MoreReason_() {
  return (
    <div className="slide1-container">
         <h1 className="h2" style={{ color:"black",fontWeight: "bold", fontSize:"35px"}}>More Reasons to Choose</h1>
          <h5 className="pt-1 h5" style={{color:"black",textDecoration:"none", lineHeight:1.2}}>
            Improving your carbon footprint is just the beginning. Whether your current packaging platform is
            CFF blisters, pillow packs, bottles or blisters with a foil over-wrap, <strong>Aclar®</strong> films offer many advantages.</h5>

           
            <div style={{backgroundColor:"rgba(243,243,243,1)",opacity:"0.8"}} >

                <table  className="table table-sm border-secondary align-middle text-center tab flex-table">
                <thead style={{borderBottom:"1px solid black"}}>
                  <tr style={{ fontWeight: "bolder" }}  >
                    <th className="head align-middle" scope="col" style={{paddingTop: "1%",paddingBottom: "1%",backgroundColor:"rgba(243,243,243,0.8196078431372549 )"}}></th>
                    <th className="head align-middle" scope="col" style={{ paddingTop: "1%",paddingBottom: "1%",backgroundColor:"rgba(243,243,243,0.8196078431372549 )" }}>Cold Form Foil <br/>(CFF)</th>
                    <th className="head align-middle" scope="col" style={{paddingTop: "1%",paddingBottom: "1%",backgroundColor:"rgba(243,243,243,0.8196078431372549 )" }}>Aclar® Barrier<br/> Film</th>
                    <th className="head align-middle" scope="col" style={{paddingTop: "1%",paddingBottom: "1%",backgroundColor: "#DCDCDC"}}>The Aclar® Advantage</th>
                  </tr>
                </thead>  
                <tbody>
                  <tr style={{borderBottom:"1px solid black"}}>
                    <th scope="row" style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )", paddingTop:"40px !important", textAlign:"left",fontSize:"18px"}}><p>Crystal-Clear <br/>Visibility</p></th>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold"}}><p>No</p></td>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold"}}><p>Yes</p></td>
                    <td style={{ width: "55%", backgroundColor: "#DCDCDC",fontSize:"14px" }}>
                     <strong>Aclar®</strong>film displays your brand’s distinct shape
                      and color to differentiate your package design.
                      Users experience up to 25% more see-through
                      clarity with no yellowing, up to 90% less haze and
                      up to 44% lower push-through force.</td>
                  </tr>
                  <tr style={{borderBottom:"1px solid black"}}>
                    <th scope="row" style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",textAlign:"left",fontSize:"18px"}}><p>Pack Size</p></th>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold", paddingTop:"2% !important"}}><p>Larger</p></td>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold"}}><p>Smaller</p></td>
                    <td style={{ width: "55%", backgroundColor: "#DCDCDC",fontSize:"14px"  }}>Reduce warehousing, transportation and shelf
                      space requirements with smaller packs; increase
                      patient portability, compliance and adherence.</td>
                  </tr>
                  <tr style={{borderBottomColor: "transparent"}}>
                    <th scope="row" style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",textAlign:"left",fontSize:"18px"}}><p>Operational <br/>Efficiencies</p></th>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold"}}><p>Less</p></td>
                    <td style={{backgroundColor:"rgba(243,243,243,0.8196078431372549 )",fontWeight: "bold"}}><p>More</p></td>
                    <td style={{ width: "55%", backgroundColor: "#DCDCDC",fontSize:"14px"  }}>Reduce raw material usage and scrap production,
                      as well as warehousing expense and transportation
                      costs; increase packaging line speed, efficiency and
                      operational flexibility. <strong>Aclar®</strong> film also allows visual
                      inspection to aid in reducing production errors.
                    </td>
                  </tr>
                </tbody>
                </table>
            </div>
            <p className="" style={{ color:"white",fontSize:"12px",textAlign:"left", filter:"brightness(100%)"}}>For more about the advantages of using <strong>Aclar®</strong> film for your sustainable barrier packaging, visit <a target="_blank" style={{textDecoration:"none", color:"white", fontWeight:"bold"}} href="https://lifesciences.honeywell.com/us/en">lifesciences.honeywell.com</a>.</p>
            {/* <button onClick={()=>setIsValid(true)} className='btn btn-small btn-primary'>submit</button> */}
            <div class="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="circle-arrow">   
            <img src={ArrowImage} alt="down arrow"/>
            </div>
        </div>
  )
}

export default MoreReason_;
