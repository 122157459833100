import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
// import "./Layout.scss";
// import { FeatureFlags } from "../../components/common/FeatureFlags/FeatureFlags";


import GlobalFooter from "../common/GlobalFooter/GlobalFooter";
import GlobalHeader from "../common/GlobalHeader/GlobalHeader";
import MainSection from "./MainSection";


const Layout = (props) => {

  const initFeatures = () => {
    // if (!sessionStorage.getItem("flags")) {
    //   sessionStorage.setItem("flags", JSON.stringify(FeatureFlags));
    // }
  };

  const { token, configData } = useSelector((state) => state.config);

  useEffect(() => {
    initFeatures();
  }, []);

  return (
    <React.Fragment>
      {/* Global Header Component */}
      <GlobalHeader />
      {token ? <MainSection token={token} configData={configData}/>:'Loading...'}
      {/** Global Footer Component */}
      <GlobalFooter />
    </React.Fragment>
  );
};
export default Layout;