import React, { Component } from "react";
import "./result.css";
// import bckContainerGif from './Pill-header@2x.png'
import { dynaPost } from "../../../../services/Service";
// import ResultForm from "./ResultForm";
import MarketoForm from "./MarketoForm";


class Result extends Component {

  //initialized state
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      states: [],
      submitInProgress:false,
      form_data: {
        capsuleSizeId:'',
        packsPerYearId:'',
        routeId:'',
        first_name: '',
        last_name: '',
        country: '',
        state: '',
        email: '',
        title: '',
        company:'',
        email_Opt_in__c:false
      },
      sucessMessage:'',
      downloadPDF:''
    }

    //bind function
    // this.getTheMasterData = this.getTheMasterData.bind(this);
    // this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailMyResult = this.handleEmailMyResult.bind(this);
  }

  /**
   * Get api for master data (countires and states)
   * @param {*} url 
   * @param {*} name 
   */
  /*
  getTheMasterData(url, name) {
    const{access_token} = this.props.token
    callToAPI(url, "GET", {
      "Content-Type": "application.json",
      "Authorization" : "Bearer "+access_token
    }).then((res) => res.json()).then((res) => this.setState({ [name]: res.data }));
  }*/

  /**
   * mount props value and initialized in local state
   */
  componentDidMount() {
    const{estimateFormData} = this.props;
    const{capsuleSizeId, packsPerYearId,routeId} = estimateFormData
    let form_data = {...this.state.form_data}
    form_data.capsuleSizeId = capsuleSizeId;
    form_data.packsPerYearId = packsPerYearId;
    form_data.routeId = routeId;
    this.setState({form_data});
    // this.getTheMasterData('/api/v1/master/country-list', 'countries') // No longer used due to embedded form of marketo
  }

  /**
   * did update re-initialize props value with states
   */
  componentDidUpdate(){
    const{estimateFormData,downloadPDF} = this.props;
    const{capsuleSizeId, packsPerYearId,routeId} = estimateFormData
    if(capsuleSizeId != this.state.form_data.capsuleSizeId){
      this.setState({form_data:{...this.state.form_data,capsuleSizeId:capsuleSizeId}});
    }
    if(packsPerYearId != this.state.form_data.packsPerYearId){
      this.setState({form_data:{...this.state.form_data,packsPerYearId:packsPerYearId}});
    }
    if(routeId != this.state.form_data.routeId){
      this.setState({form_data:{...this.state.form_data,routeId:routeId}});
    }

    if(this.state.downloadPDF !== downloadPDF){
      this.setState({downloadPDF:downloadPDF});
    }

  }

  /**
   * handle change for country
   * @param {*} id 
   */

  /*
  handleChangeCountry(id) {
    //allowed country for states
    var ids = [101,231,38,12,105,142,107,30,44]
    if (id &&  ids.includes(parseInt(id.split('-')[0]))) {
      const{access_token} = this.props.token
      // this.setState({ form_data: { ...this.state.form_data, country: id.split('-')[1] } })
      callToAPI('/api/v1/master/state-list/' + id.split('-')[0], "GET", {
        "Content-Type": "application.json",
        "Authorization" : "Bearer "+access_token
      }).then((res) => res.json()).then((res) => this.setState({ states: res.data }));
    } else {
      this.setState({ states: [] });
    }
  }*/

  handleChange(n, v) {
    this.setState({ form_data: { ...this.state.form_data, [n]: v } })
  }

  renderJobTitle = (length) => {
    
  }

  /**
   * email my result should trigger on screen 7
   * @param {*} data 
   */

  handleEmailMyResult(data){

    const{estimateFormData} = this.props;
    const{capsuleSizeId, packsPerYearId,routeId} = estimateFormData
    let form_data = {...data}
    form_data.capsuleSizeId = capsuleSizeId;
    form_data.packsPerYearId = packsPerYearId;
    form_data.routeId = routeId;
    form_data.country = form_data.country ? form_data.country.split("-")[1]:'';
    this.setState({form_data,submitInProgress:true},() => {
      const{access_token} = this.props.token
      dynaPost('/api/v1/email/my-result',this.state.form_data,access_token).then((res) => {
          let form_data = {
            capsuleSizeId:'',
            packsPerYearId:'',
            routeId:'',
            first_name: '',
            last_name: '',
            country: '',
            state: '',
            email: '',
            title: '',
            company:'',
            email_Opt_in__c:false
          }
          this.setState({submitInProgress:false,form_data,sucessMessage:'The result has been sent to your email.'})
          setTimeout(() => {
            this.setState({sucessMessage:''})
          },3000)
      }).catch((errors) => console.log(errors));
    })
    
  }

  render() {
    
    return (
      <div className="bck">
        <div className="row">
          <div className=" col-sm-12 imagine">
            <h1>IMAGINE <span>WHAT'S</span><span>POSSIBLE</span></h1>           
            <p>Smaller pack sizes. Less packaging material.
              Reduced emissions and waste. This is just a
              snapshot of what’s possible when you use 
              <strong>&nbsp;Aclar®</strong> film instead of CFF. Imagine the impact you
              could make by implementing <strong>Aclar®</strong> film across
              your entire portfolio.</p>
            
          </div>
          <MarketoForm formId={'7540'} downloadPDF={this.state.downloadPDF}/>
          {/* <ResultForm {...this.state} handleEmailMyResult={this.handleEmailMyResult} handleChange={this.handleChange} handleChangeCountry={this.handleChangeCountry}/> */}
         
        </div>
      </div>
    )
  }
};

export default Result;