// import "./App.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncData } from "./storage/reducers/HeaderFooterDataReducer";
import {
  fetchAsyncConfig,
  fetchAsyncToken,
  fetchAsyncTokenValidation,
  fetchAsyncSoldToValues,
} from "./storage/reducers/configReducer";
import Layout from "./components/pages/Layout";
import C from "./constants/Constant";
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const { token } = useSelector((state) => state.config);

  let tokenValidate;
  if (token) {
    if (token.access_token) {
      tokenValidate = token.access_token;
    }
  }

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.headerFooterData);
  const AppLoading = useSelector((state) => state.config);
  const sessionData = sessionStorage.getItem("persist:root");

  const [isLoaded, setLoadedStatus] = useState(true)

  /**
   * Fetch token and configuration from init
   */
  useEffect(() => {
    dispatch(fetchAsyncToken()); 
    if (isLoaded) {
      setLoadedStatus(false)
      dispatch(fetchAsyncData());
      dispatch(fetchAsyncConfig());
    }

    if (tokenValidate) {



    }
    /*eslint-disable */
  }, []);

  useEffect(() => {
    if (tokenValidate) {
      // dispatch(fetchAsyncTokenValidation(tokenValidate));
      // dispatch(fetchAsyncSoldToValues(tokenValidate));
    }
  }, [tokenValidate]);

  /**
   * Fragment the screen initialize loader 
   */
  return (
    <React.Fragment>
      {AppLoading.status === "loading" ? <div className="loader text-center pt-3"><h1>Loading...</h1><div class="spinner-border m-5" role="status"></div></div>: <Layout></Layout>}
    </React.Fragment>
  );
}
export default App;
