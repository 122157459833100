import React from 'react';
import { Formik } from 'formik';
import './CalculatorForm.css'

function generateOptions(d, c) {
  if (d && d.length > 0) {
    return d.map((item, key) => {
      if (c === 'capsule') {
        const { capsuleSize, capsuleID } = item
        return <option key={key} value={capsuleID}>{capsuleSize}</option>
      }
      if (c === 'number_of_pack') {
        const { packsPerYear, packsPerYearId } = item
        return <option key={key} value={packsPerYearId}>{packsPerYear}</option>
      }
      if (c === 'transport_route') {
        const { routeId, fromCity, toCity, transportMode } = item
        return <option key={key} value={routeId}>{fromCity + ' - ' + toCity + '(' + transportMode + ')'}</option>
      }
    })
  }
}

function CalculatorForm({ handleChange,submitInProgress,capsuleData, numberOfPackData, transportData, estimateFormData,  handleEastimateClick }) {
  const { capsuleSizeId, packsPerYearId, routeId } = estimateFormData;
  return (
    <div className="col" style={{ textAlign: "-webkit-center", height:"100%"}}>
      <Formik
        enableReinitialize
        initialValues={estimateFormData}
        validate={values => {
          const errors = {};
          if (!values.capsuleSizeId) {
            errors.capsuleSizeId = '*Required field';
          }
          if (!values.packsPerYearId) {
            errors.packsPerYearId = '*Required field';
          }
          if (!values.routeId) {
            errors.routeId = '*Required field';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleEastimateClick(values)
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange : handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>


            <div className="card calForm" style={{ padding: "5%", textAlign: "left", maxWidth: "75vh", marginTop: "4%", backgroundColor:"rgba(243,243,243,0.8196078431372549 )",opacity:"0.9982749819755554",  borderRadius: "0" }}>
              <h3 className="text-dark ">See the Difference</h3>
              <span className="text-dark ">Choose from the following options and see how<br/> <strong>Aclar®</strong> helps with your sustainability initiatives.</span>
              <select name="capsuleSizeId" aria-label='capsuleSize' aria-labelledby='capsuleSize'
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.capsuleSizeId} className="mt-3 " style={{ border: "none", paddingTop:"20px", paddingBottom:"20px", paddingLeft:"10px" }}>
                <option value='' disabled  hidden>Capsule Size</option>
                
                {generateOptions(capsuleData, 'capsule')}
              </select>
              <span className='error'>{errors.capsuleSizeId && touched.capsuleSizeId && errors.capsuleSizeId}</span>

              <select className=" mt-3" name="packsPerYearId" aria-label='packsPerYearId' aria-labelledby='packsPerYearId'
               onChange={handleChange}
               onBlur={handleBlur}
               value={values.packsPerYearId} style={{ border: "none",paddingTop:"20px", paddingBottom:"20px", paddingLeft:"10px" }}>
                <option value='' disabled  hidden>Number of Packs Per Year</option>
                {generateOptions(numberOfPackData, 'number_of_pack')}
              </select>
              <span className='error'>{errors.packsPerYearId && touched.packsPerYearId && errors.packsPerYearId}</span>

              <select className=" mt-3" name="routeId" aria-label='route' aria-labelledby='route'
                onChange={handleChange}
                onBlur={handleBlur}
               value={values.routeId} style={{ border: "none",paddingTop:"20px", paddingBottom:"20px", paddingLeft:"10px" }}>
                <option value='' disabled  hidden>Transport Route</option>
                {generateOptions(transportData, 'transport_route')}
              </select>
              <span className='error'>{errors.routeId && touched.routeId && errors.routeId}</span>

              <div className="col">
                <button className="calculate" disabled={submitInProgress} type='submit' >{submitInProgress ? 'Loading result...':'CALCULATE'}</button>

              </div>
            </div>
          </form>

        )}
      </Formik>

    </div>
  );
}

export default CalculatorForm;