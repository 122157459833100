import React, { useState } from "react";
import "./GlobalHeader.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";

const GlobalHeader = (props) => {
  const data = useSelector((state) => state.headerFooterData);
  const AppLoading = useSelector((state) => state.config);
  const { Adobe_Analytics } = AppLoading.configData; //Adobe Analytics data
  const [adobeScriptPlaced, AdobeScriptPlaceStatus] = useState(true)

  // loading header css and js files and creating corresponding link tags
  useEffect(() => {


    if (data) {
      //Setting CSS using Map Function Ends
      setTimeout(() => {
        //your code to be executed after 2 second
        data?.css?.map((cssUrlValue) => {
          const cssFile = document.createElement("link");
          cssFile.href = cssUrlValue;
          cssFile.rel = "stylesheet";
          document.head.appendChild(cssFile);
        });

        //Setting JS using Map Function Ends
        data.jsFile?.map((jsUrlValue) => {
          const scriptFile = document.createElement("script");
          scriptFile.src = jsUrlValue;
          document.body.appendChild(scriptFile);
        });

        //update href
        console.log('refresh honeywell href')
        window.$('#root #myAeroNavMnuDvNew .aem-Grid header .logo-container a').attr('href','https://pmt.honeywell.com/');
      }, 200);
    }



  }, [data]);




  return (
    <div id="myAeroNavMnuDvNew">
      <div dangerouslySetInnerHTML={{ __html: data.header }} />
    </div>
  );
};

export default GlobalHeader;
