import { Splide, SplideSlide } from '@splidejs/react-splide';
import React,{Component} from 'react'
import EnvisionYourImpact from './EnvisionScreen'
import './index.css'
import '@splidejs/react-splide/css'
import Shipping from './shipping/Shipping';
import Packaging_ from './packaging/Packaging_';
import Co2emission from './co2emission/Co2emission_';
import { callToAPI, callToPostAPI, dynaPost } from '../../../services/Service';
import Cardboard from './cardboard/Cardboard_';
import MoreReason_ from './moreReason/MoreReason_';
import Result from './result/Result'
import { Intersection } from "@splidejs/splide-extension-intersection"
import MarketoFormPopup from './MarketoFormPopup/MarketoFormPopup';
class MainSection extends Component {

    //initialize state
    constructor(props){
      super(props);
      this.state = {
        capsuleData : [],
        numberOfPackData : [],
        transportData:[],
        estimateFormData : {
          capsuleSizeId : '',
          packsPerYearId : '',
          routeId : '',
        },
        wheelAndPaginationStatus : true,
        autoplayslide : true,
        popupfalg:false,
        popupfalgauto:false,
        estimateResult : [],
        staticScreenLocak : false,
        submitInProgress : false,
        loadSlider:false,
        downloadPDF : '',
        Adobe_Analytics:[]
      }
  
      //ref
      this.slider_ref = React.createRef();  

      ///bind function
      this.handleEastimateClick = this.handleEastimateClick.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getTheMasterData = this.getTheMasterData.bind(this);
      this.handleAutoPlay = this.handleAutoPlay.bind(this);
      this.handleAutoPlayPause = this.handleAutoPlayPause.bind(this);

    }

  /**
   * Get master data for capsule list, number of pack and transport route
   * @param {*} url 
   * @param {*} name 
   */
  getTheMasterData(url,name){
    const{access_token} = this.props.token
    callToAPI(url, "GET", {
      "Content-Type": "application.json",
      "Authorization" : "Bearer "+access_token
    }).then((res) => res.json()).then((res) => this.setState({[name]:res.data}));
  }



  componentDidMount(){
      this.getTheMasterData('/api/v1/master/capsule-list','capsuleData')
      this.getTheMasterData('/api/v1/master/number-of-pack','numberOfPackData')
      this.getTheMasterData('/api/v1/master/transport-route','transportData');

      const{Adobe_Analytics} = this.props.configData
      if(Adobe_Analytics && Adobe_Analytics.length  !== this.state.Adobe_Analytics.length){
        this.setState({Adobe_Analytics:Adobe_Analytics},() => {
          this.initializeCookieScript();//intialize cookie script
        })
      }
  }

  componentDidUpdate(){
    const{Adobe_Analytics} = this.props.configData
    if(Adobe_Analytics && Adobe_Analytics.length  !== this.state.Adobe_Analytics.length){
      this.setState({Adobe_Analytics:Adobe_Analytics},() => {
        this.initializeCookieScript();//intialize cookie script
      })
    }
  }

  /**
   * Dynamically setup cookie script from configuration api
   */
  initializeCookieScript(){
    setTimeout(() => {
        const{Adobe_Analytics} = this.state;
        if(Adobe_Analytics && Adobe_Analytics[0]) {
          if (!document.getElementById('adobeScript')) {
            let adobescript = document.createElement("script");
            adobescript.id = "adobeScript";
            adobescript.type = 'text/javascript';
            adobescript.src = Adobe_Analytics[0];
            document.head.appendChild(adobescript);
          }
  
          if (!document.getElementById('otSDKStubScript')) {
            let otScript = document.createElement('script');
            console.log('otSDKStubScript loaded')
            otScript.id = 'otSDKStubScript';
            otScript.src = '//cdn.cookielaw.org/scripttemplates/otSDKStub.js';
            otScript.type = 'text/javascript';
            otScript.setAttribute("data-document-language","true");
            otScript.setAttribute("charset","UTF-8");
            otScript.setAttribute("data-domain-script",Adobe_Analytics[1]);
            document.head.appendChild(otScript);
           
          }

          if (document.getElementById('adobeScript') && document.getElementById('otSDKStubScript')) {
            // if(!document.getElementById('loaded_function_script')){
            //     let onLoad = document.createElement('script');
            //     onLoad.id = 'loaded_function_script';
            //     onLoad.type = 'text/javascript';
            //     // onLoad.src = Adobe_Analytics[2];
            //     onLoad.innerHTML = "function OptanonWrapper() { console.log(`optanoWrapper called`) }";
            //     document.head.appendChild(onLoad);
            // }
          }
        }
        
      
    }, 200);
  }

 /**
  * handle for estimate result button
  * @param {*} values 
  */
  handleEastimateClick(values){
    this.setState({estimateFormData:values,submitInProgress:true,loadSlider:false},() => {
      const{access_token} = this.props.token
      dynaPost('/api/v1/estimate/calculate',values,access_token).then((res) => {
        this.setState({estimateResult:res.data,loadSlider:true},() => {
          this.setState({wheelAndPaginationStatus:true,staticScreenLocak:true});
          if(res && res.data && res.data[0] && res.data[0]['PDF-URL']){
            this.setState({downloadPDF:res.data[0]['PDF-URL']})
          }
          setTimeout(() => {
            this.setState({submitInProgress:false});
          //  this.slider_ref.current.go(1)
          //  this.slider_ref.current.hasAutoplayControls =true;
          
          },500);
          
        })
      });
    })
  }

  /**
   * handle for change data from form input of calculator
   * @param {*} n 
   * @param {*} v 
   */
  handleChange(n,v){
    this.setState({ estimateFormData: { ...this.state.estimateFormData, [n]: v} },() =>{
      const{capsuleSizeId, packsPerYearId,routeId} = this.state.estimateFormData;
      if(capsuleSizeId || packsPerYearId || routeId){
       this.setState({wheelAndPaginationStatus:true});
      }else{
        this.setState({wheelAndPaginationStatus:false});
      }
    });
    
  }

  handleAutoPlayPause(e){
    console.log(e.target.value)
   debugger
    e.target.classList.toggle("active");
    if(e.target.classList.length !== 1){
      this.setState({autoplayslide :false}, () => {
        console.log(this.state.autoplayslide, 'pause');
       }); 
      }else{
        this.setState({autoplayslide :true}, () => {
          console.log(this.state.autoplayslide, 'play');
         }); 
      }
  
  }

  handleAutoPlay(){
 
      this.setState({autoplayslide:true,popupfalgauto :true}, () => {
        console.log(this.state.autoplayslide, 'play');
       }); 
    
      
     

      
    }

  /**
   * rendering splide slider based on result generated
   * @returns 
   */
    renderSlider(){
   
      const{estimateResult} = this.state
      if(estimateResult && estimateResult.length > 0){
        return estimateResult.map((item,key) => {
           const{pageIndex} = item
           if(parseInt(pageIndex) === 1){
            return (
              <SplideSlide key={key}> 
                  <Shipping {...item}/>
              </SplideSlide>
            )
           }else if(parseInt(pageIndex) === 2){
             return (
              <SplideSlide key={key}>
                    <Packaging_ {...item}/>
              </SplideSlide>
             )
           }else if(parseInt(pageIndex) === 3){
            return(
              <> <SplideSlide key={key}>
                    <Co2emission {...item}/>
              </SplideSlide>
             </>
            )
           }else if(parseInt(pageIndex) === 4){
            return(
              <SplideSlide key={key}>
                    <Cardboard {...item}/>
              </SplideSlide>
            )
           }
        })
      }
    }

    render(){
     
        const{wheelAndPaginationStatus,staticScreenLocak,loadSlider} = this.state
        const{autoplayslide,popupfalg,popupfalgauto} = this.state
        /**
         * on initial page load the slider will be not render, it will render after selection of user's input and pressing on estimate result
         */
        if(!loadSlider){ 
          return <EnvisionYourImpact {...this.state} handleEastimateClick={this.handleEastimateClick} handleChange={this.handleChange}/>
        }
        /**
         * Splide and some configuration regarding slider settings
         */
        return(
          <>
       
            <Splide key={"splide"+autoplayslide}
              onMoved={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                console.log(this.slider_ref.current?.splide?.Components?.Controller?.getIndex())
                  if(this.slider_ref.current?.splide?.Components?.Controller?.getIndex() === 2 && popupfalg === false){
                   this.setState({popupfalg :true,autoplayslide: false}, () => {
                  console.log(this.state.popupfalg, 'popup falg');
                 }); 
                }
              }}
              ref={this.slider_ref}
              hasAutoplayControls
              options={{
                rewind:true,
                gap:'2rem',
                type:'loop',
                waitForTransition:true,
                wheelSleep:500,
                speed:700,
                index:1,
                direction:'ttb',
                paginationDirection:'ttb',
                hasAutoplayControls:true,
                start:autoplayslide && !popupfalg ? 1: this.slider_ref.current?.splide?.Components?.Controller?.getIndex(),
                height:'750px',
                interval: 3000,
                intersection: {
                inView: {
                autoplay:autoplayslide,
                },
                },
                autoHeight:true,
             
                // fixedHeight:'570px',
                perPage:1,
                wheel:wheelAndPaginationStatus,
                pagination:wheelAndPaginationStatus,
                arrows:false,
                // cover:true,
              }}
             
              extensions={{Intersection}}
           >
            <SplideSlide>
                <EnvisionYourImpact {...this.state} handleEastimateClick={this.handleEastimateClick} handleChange={this.handleChange}/>
            </SplideSlide>
            {this.renderSlider()}     
            { staticScreenLocak ? <SplideSlide> <MoreReason_/> </SplideSlide>:'' }       
            { staticScreenLocak ? <SplideSlide> <Result token={this.props.token} downloadPDF={this.state.downloadPDF} estimateFormData={this.state.estimateFormData}/> </SplideSlide>:'' }    
          
            {this.state.popupfalg && this.state.popupfalgauto === false && <MarketoFormPopup onHandlePlay={this.handleAutoPlay}/>} 
           
          </Splide>
          <button className='button-play' onClick={this.handleAutoPlayPause}></button>
       
         </>
        )
    }

}

export default MainSection