import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/Service";

const initialState = {
    configData: '',
    token: "",
    tokenValid: false,
    soldToNumbers: [],
    isLoadingToken:false,
    isHoneywellEmployee:false,
    status: "",
}

//loading config data
export const fetchAsyncConfig = createAsyncThunk("msp/config", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/get-config", "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

//loading token
export const fetchAsyncToken = createAsyncThunk("/msp/token", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/get-token", "GET", {
      "Content-Type": "application.json",
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

//validating token
export const fetchAsyncTokenValidation = createAsyncThunk(
  "/msp/tokenvalidation",
  async (token_msp) => {
    console.log(token_msp)
    try {
        const res = await callToAPI('/api/v1/auth/check-access', 'GET', { 'authorization': `Bearer ${token_msp}` })
        const data = await res.json()
        if (res.status === 200) {
            return {tokenAccess:data?.message?.result?.toolAccess?.status,honeywellEmployee:data?.message?.result?.isInternalUser}
        } else {
            return {tokenAccess:false,honeyWellEmployee:false}
        }
    } catch (error) {
      console.log(error);
    }
  }
);

// loading sold to values
export const fetchAsyncSoldToValues = createAsyncThunk(
  "/msp/soldtonumbers",
  async (token_msp) => {
    try {
      const res = await callToAPI("/api/v1/auth/get-sold-to-number", "GET", {
        authorization: `Bearer ${token_msp}`,
      });
      const data = await res.json();
      return data?.message?.result;
    } catch (error) {
      console.log(error);
    }
  }
);

export const configReducer = createSlice({
    name: "configdata",
    initialState,
    extraReducers: {
        [fetchAsyncConfig.fulfilled]: (state, { payload }) => {
            return { ...state, configData: payload.message.result }
        },
        [fetchAsyncToken.pending]: (state, { payload }) => {
          state.status = "loading";
        },
        [fetchAsyncToken.fulfilled]: (state, { payload }) => {
            return { ...state, token: payload,status: "success" }
        },
        [fetchAsyncTokenValidation.pending]: (state, { payload }) => {
            return { ...state, isLoadingToken:true }
        },
        [fetchAsyncTokenValidation.fulfilled]: (state, { payload }) => {
            return { ...state, tokenValid:payload.tokenAccess,isHoneywellEmployee:payload.honeywellEmployee,isLoadingToken:false }
        },
        [fetchAsyncTokenValidation.rejected]: (state, { payload }) => {
            return { ...state,isLoadingToken:false,tokenValid:false,isHoneywellEmployee:false }
        },
        [fetchAsyncSoldToValues.fulfilled]: (state, { payload }) => {
            return { ...state, soldToNumbers: payload }
        }
    }
})
export const configActions = configReducer.actions
