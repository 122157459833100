import { render } from "@testing-library/react";
import React from "react";
import "./co2emission.css";
import videoURL from './GettyImages-1369604706.mp4'
import CarImage from '../../../../assets/image/Desktop/Car@2x.png'
import ResultTableDiv from "../common_result_table/ResultTableDiv";
import { numberWithCommas } from "../../../common/lib/helper";
import ArrowImage from '../../../../assets/image/Desktop/down.png'

function Co2emission({ Calculation,MainHeadingValue, MainHeadingUnit, SubHeadingValue, subHeadingUnit }) {
  
  var xxTons = 0;
  if(Calculation && Calculation.length > 0){
    if(Calculation[2] && Calculation[2].annualSavingsTons){
      xxTons = Calculation[2].annualSavingsTons;
    }
  }

  return (
    <div className="mainContainer5">
      <div className="row">
        <div className="col d-sm-block video2">
          <video id="background-video5"  loop autoPlay muted playsInline>
            <source src="/stream-video?video_name=emission.mp4" type="video/mp4" />
            <source src={videoURL} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className=" row co2emission" >
        <div className="col-8 co2emission-heading" style={{ textAlign: "left", maxWidth:"63%" }}>
          <h1 style={{ textWeight: "bold" }}>Cut CO₂ emissions by</h1>
          <h2><a style={{ color: "red", fontWeight: "bold" }}>{MainHeadingValue}{MainHeadingUnit}</a> ({xxTons} tons) annually.¹</h2>
        
          <h2>That’s equal to <a style={{ color: "red", fontWeight: "bold" }}>{numberWithCommas(SubHeadingValue)} {subHeadingUnit}</a> <br/> driven in an average car.</h2>
          <h4 style={{ fontSize: "16px"}}>
            By using <strong>Aclar®</strong> films, you can ship the same<br />
            number of doses as CFF-packaged drugs<br />
            while reducing total packaging weight, which<br />
            can help reduce transportation-related<br />
            environmental impact.
          </h4>
          <a style={{ fontSize: "10px" }}>¹ Based on weight reduction.</a>
        </div>
        <div className="col-4 right-side-image-co2emission" style={{ textAlign: "center" }}>
          <img src={CarImage} className="image"/>
        </div>
        
      </div>
      <div className="row">
        <div className="col">
          <ResultTableDiv data={Calculation} screen={'screen-3'}/>
        </div>
      </div>
      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="circle-arrow">   
        <img src={ArrowImage} alt="down arrow"/>
      </div>
    </div>
  )


};
export default Co2emission;
