import Http from '../utils/Http'

export function dynaPost(url, data, access_token) {
 
  return new Promise((resolve, reject) => {
    Http.defaults.headers.common['Authorization'] = "Bearer "+access_token;
    Http.post(url, data)
      .then(res => {
        return resolve(res.data)
      })
      .catch((err) => {
        return reject(err);
      })
  })

}

export const callToPostAPI = async (routeUrl, methodData, headerContent,data) => {
  const res = await fetch(routeUrl, {
    method: methodData,
    headers: headerContent,
    body : JSON.stringify(data)
  });

  return res;
};


export const callToAPI = async (routeUrl, methodData, headerContent) => {
  const res = await fetch(routeUrl, {
    method: methodData,
    headers: headerContent,
  });

  return res;
};
