import React from 'react';
import './resultTable.css';

function ResultTableHeader() {



    return (
        <div className="row result-table-header " style={{ fontSize: "18px", textAlign: "left",  border: "transparent" }}>
            <div className="col">
                <div className="row d-flex align-items-center" style={{ color: "white" }}>
                    <div className="col-3" style={{  paddingBottom:"5px", paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}></div>
                    <div className="col-2" style={{ paddingLeft:"5%", paddingBottom:"5px", paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}>Aclar®</div>
                    <div className="col-1" style={{  paddingBottom:"5px", paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}></div>
                    <div className="col-2" style={{ paddingLeft:"5%",paddingBottom:"5px", paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}>CFF</div>
                    <div className="col-1" style={{ paddingBottom:"5px", paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}></div>
                    <div className="col-3" style={{paddingBottom:"5px", backgroundColor: "grey", opacity: 1, paddingTop: "5px", textAlign: "left", fontSize: "21px", letterSpacing: "0px", color: " #FFFFFF", fontWeight: 400 }}>Annual Savings</div>
                </div>
            </div>
        </div>
    )
}

export default ResultTableHeader;