import { render } from "@testing-library/react";
import React, { Component } from "react";
import "./packaging.css";
import videoURL from './GettyImages-1315555572.mp4';
import ResultTableDiv from "../common_result_table/ResultTableDiv";
import {numberWithCommas} from "../../../common/lib/helper";
import ArrowImage from '../../../../assets/image/Desktop/down.png'

function Packaging_({ Calculation, MainHeadingValue, MainHeadingUnit, SubHeadingValue, subHeadingUnit }) {
  return (
    <div className="mainContainerpack">
      <div className="row  ">
        <div className="col d-sm-block video1">
        <video id="background-video2"  loop autoPlay muted playsInline>
          <source src="/stream-video?video_name=packaging.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
      <div className="main2 row main-packaging">
        
          <div className="col-8">
            <h1 >Save up to <a style={{ color: "#DC202E", fontWeight:"bold" }}>{numberWithCommas(MainHeadingValue)} {MainHeadingUnit}</a> in <br/>packaging weight annually.</h1>          
            <h2 style={{paddingTop:"2%"}}>That’s equal to <a style={{ color: "#DC202E", fontWeight:"bold"}}>{numberWithCommas(SubHeadingValue)}</a>
            <br/><a style={{ color: "#DC202E", fontWeight:"bold" }}>{subHeadingUnit}</a> of office paper.
            </h2>  
            <h4 style={{ fontSize: "18px" , paddingTop:"2%"}}>
              With <strong>Aclar®</strong> film, you benefit from an efficient and<br />
              flexible thermoforming platform that offers the<br />
              opportunity for smaller pack sizes. With a smaller<br />
              pack size, reduction in weight can be significant.
            </h4>
            <br/>
          </div>
          <div className="col-4 pack_gif" style={{ textAlign: "center" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382.392 533.738">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_8" data-name="Rectangle 8" width="382.392" height="533.738" fill="none" stroke="#fff" stroke-width="20" />
                </clipPath>
              </defs>
              <g id="Paper" transform="translate(-3)">
                <g id="Group_6" data-name="Group 6" transform="translate(3)" clipPath="url(#clip-path)">
                  <path id="Path_2" data-name="Path 2" d="M3.819,187.633l129.7,70.456,225.78-120.9" transform="translate(1.315 47.256)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_3" data-name="Path 3" d="M3.819,155.476l129.7,70.456,225.78-120.9" transform="translate(1.315 36.179)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_4" data-name="Path 4" d="M3.819,219.791l129.7,70.456,225.78-120.9" transform="translate(1.315 58.333)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_5" data-name="Path 5" d="M3.819,251.949l129.7,70.456,225.78-120.9" transform="translate(1.315 69.409)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_6" data-name="Path 6" d="M3.819,284.107l129.7,70.456,225.78-120.9" transform="translate(1.315 80.486)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_7" data-name="Path 7" d="M3.819,316.264l129.7,70.456,225.78-120.9" transform="translate(1.315 91.563)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_8" data-name="Path 8" d="M3.819,348.422l129.7,70.456,225.78-120.9" transform="translate(1.315 102.639)" fill="none" stroke="#fff" stroke-width="20" />
                  <path id="Path_9" data-name="Path 9" d="M211.825,9.158,26.078,105.235v.009L18.237,155.73l-.009.059L128.56,215.723l225.778-120.9Z" transform="translate(6.278 3.154)" fill="none" stroke="#fff" stroke-width="20" />
                </g>
                <path id="Path_10" data-name="Path 10" d="M26.073,80.627l-7.839,50.485L67.59,105.584Z" transform="translate(8.354 27.772)" fill="none" stroke="#fff" stroke-width="10" />
              </g>
            </svg>

          </div>
       
      </div>
      <ResultTableDiv data={Calculation} screen={'screen-2'}/>
      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="circle-arrow">   
          <img src={ArrowImage} alt="down arrow"/>
          </div>
    </div>
  )
}


export default Packaging_;
