

import React, { useEffect, useState } from 'react';
import "../MarketoFormPopup/MarketoFormPopup.css"

function MarketoFormPopup(props) {

  const formId = '7808';
  const marketoScriptId = 'mktoForm_' + formId; //prod
  const [isLoaded, setIsLoaded] = useState(true);
  const [successMessage, setMessageStatus] = useState(false);


  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript();
    } else {
      setIsLoaded(true);
    }

  }, []);


  const marketoCallback = (form) => {
    var validator = new RegExp('^[A-Za-z][a-zA-Z ]+$');
    window.MktoForms2?.lightbox(form).show();
    //listen for the validate event
    form.onValidate(function () {
      // Get the values
      var vals = form.vals();
      //Check your condition
      if (vals.FirstName == "") {
        // Prevent form submission
        form.submittable(false);
        var FirstName = form.getFormElem().find("#FirstName");
        form.showErrorMessage("The first name field is required!", FirstName);
        return false;

      } else if (vals.FirstName && !validator.test(vals.FirstName)) {
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var FirstName = form.getFormElem().find("#FirstName");
        form.showErrorMessage("Please enter alphabets!", FirstName);

      }
      else if (vals.LastName && !validator.test(vals.LastName)) {
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var LastName = form.getFormElem().find("#LastName");
        form.showErrorMessage("Please enter alphabets!", LastName);

      }
      else if (vals.LastName && !validator.test(vals.LastName)) {
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var LastName = form.getFormElem().find("#LastName");
        form.showErrorMessage("Please enter alphabets!", LastName);

      } else if (vals.Title && !validator.test(vals.Title)) {
        form.submittable(false);
        var Title = form.getFormElem().find("#Title");
        form.showErrorMessage("Please enter alphabets!", Title);
      }
      else {
        // Enable submission for those who met the criteria
        form.submittable(true);
      }
    });



    form.onSuccess(function (values) {

      // Get the form's jQuery element and hide it
      form.getFormElem().hide();
      setMessageStatus(true);
      // Return false to prevent the submission handler from taking the lead to the follow up url
      console.log('form trigered')
      props.onHandlePlay();
      return false;
    });


  }

  useEffect(() => {

    isLoaded &&
      window.MktoForms2?.loadForm(
        '//pages2.honeywell.com',
        '828-DHL-685',
        formId,
        marketoCallback,

      )

  }, [isLoaded, formId]);



  const loadScript = () => {
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//pages2.honeywell.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function () {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);

  };

  return (
    <>

    </>
  );
}

export default MarketoFormPopup;