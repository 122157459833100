import React from "react";
import "./shipping.css";
import videoURL from './GettyImages-1340051689.mp4'
import ShippingImage from '../../../../assets/image/Desktop/Shipping Container.png'
import ResultTableDiv from "../common_result_table/ResultTableDiv";
import { numberWithCommas } from "../../../common/lib/helper";
import ArrowImage from '../../../../assets/image/Desktop/down.png'

function Shipping(props) {
  const {  MainHeadingValue, MainHeadingUnit, SubHeadingValue, Calculation } = props ? props : {}
  return (
    <div className="mainContainerShip">
      <div className="row ">
        <div className="col d-sm-block video" >
          <video id="background-video1"  loop autoPlay muted playsInline>
            <source src="/stream-video?video_name=shipping.mp4" type="video/mp4" />
            <source src={videoURL} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="eliminate-shipping shipping-main row" >
        <div className="col-8 " style={{ textAlign: "left", height:"100%"}}>
          <h1 style={{ textWeight: "bold" }}>Eliminate <span style={{ color: "#dc202e", fontWeight: "bold" }}>{numberWithCommas(MainHeadingValue)} {MainHeadingUnit}</span>
          <br/> of packaging <br/>material annually.
          </h1>
        
          <h2 style={{paddingTop:"1%"}}>That adds up to the <br/>
          volume of <span style={{ color: "#dc202e", fontWeight: "bold" }}>{numberWithCommas(SubHeadingValue)} 40-ft.</span>
         <br/>shipping containers </h2>     
          <h4 style={{ fontSize: "16px", lineHeight:1.2 }}>
            Using thermoformed <strong>Aclar®</strong> blisters can<br />
            reduce pack size and secondary<br />
            packaging up to 55% compared to the<br />
            same material packaged in CFF.
          </h4>
          <br/>
        </div>
        <div className="col-4 right-side-image-shipping" style={{ textAlign: "right", height:"100%" }}>
             
                <img src={ShippingImage} className="image"/>
          {/* <img src={shippingContainerGif}  style={{ width: "61%", paddingTop: "25%", paddingLeft: "9%" }} /> */}
        </div>
      </div>

      <ResultTableDiv data={Calculation} screen={'screen-1'}/>
      {/* <ResultTable data={Calculation}/> */}
      <div class="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="circle-arrow">
          <img src={ArrowImage} alt="down arrow"/>
      </div>
      
    </div>
  )
}

export default Shipping;