import React, { useEffect, useState } from 'react';



function MarketoForm({ downloadPDF }) {
  const formId = '7540';
  // const marketoScriptId = 'mktoForm_4188'; //stage form
  const marketoScriptId = 'mktoForm_'+formId; //prod
  const [isLoaded, setIsLoaded] = useState(false);
  const [successMessage, setMessageStatus] = useState(false);
  useEffect(() => {
    if (!document.getElementById(marketoScriptId)) {
      loadScript();
    } else {
      setIsLoaded(true);
    }
  }, []);

  const marketoCallback = (form) => {
    var validator = new RegExp('^[A-Za-z][a-zA-Z ]+$');
    //listen for the validate event
    form.onValidate(function () {
      // Get the values
      var vals = form.vals();
      //Check your condition
      if (vals.FirstName == "") {
        // Prevent form submission
        form.submittable(false);
        var FirstName = form.getFormElem().find("#FirstName");
        form.showErrorMessage("The first name field is required!", FirstName);
        return false;

      }else if(vals.FirstName && !validator.test(vals.FirstName)){
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var FirstName = form.getFormElem().find("#FirstName");
        form.showErrorMessage("Please enter alphabets!", FirstName);
      
      }
      else if(vals.LastName && !validator.test(vals.LastName)){
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var LastName = form.getFormElem().find("#LastName");
        form.showErrorMessage("Please enter alphabets!", LastName);
      
      }
      else if(vals.LastName && !validator.test(vals.LastName)){
        // Show error message, pointed at VehicleSize element
        form.submittable(false);
        var LastName = form.getFormElem().find("#LastName");
        form.showErrorMessage("Please enter alphabets!", LastName);
      
      }else if(vals.Title && !validator.test(vals.Title)){
        form.submittable(false);
        var Title = form.getFormElem().find("#Title");
        form.showErrorMessage("Please enter alphabets!", Title);
      }
      else {
        // Enable submission for those who met the criteria
        form.submittable(true);
      }
    });

    form.vals({ "aclarImpactEstimatorReportURL":downloadPDF});

    form.onSuccess(function (values, followUpUrl) {
      // Get the form's jQuery element and hide it
      form.getFormElem().hide();
      console.log(values)
      setMessageStatus(true);
      
      // Return false to prevent the submission handler from taking the lead to the follow up url
      console.log('form trigered')
      return false;
    });

    
  }

  useEffect(() => {
    isLoaded &&
      // window.MktoForms2.loadForm(
      //   '//036-WYR-106.mktoweb.com',
      //   '036-WYR-106',
      //   formId,
      //   marketoCallback
      // );
      window.MktoForms2?.loadForm(
        '//pages2.honeywell.com',
        '828-DHL-685',
        formId,
        marketoCallback
      );
  }, [isLoaded, formId]);

  const loadScript = () => {
    var s = document.createElement('script');
    s.id = marketoScriptId;
    s.type = 'text/javascript';
    s.async = true;
    // s.src = '//036-WYR-106.mktoweb.com/js/forms2/js/forms2.min.js'; //stage
    s.src = '//pages2.honeywell.com/js/forms2/js/forms2.min.js';
    s.onreadystatechange = function () {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        setIsLoaded(true);
      }
    };
    s.onload = () => setIsLoaded(true);
    document.getElementsByTagName('head')[0].appendChild(s);
  };

  return (
    <></>
  );
}

export default MarketoForm;