import React from "react";
import ReactDOM from "react-dom/client";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import App from "./App";
import { HashRouter, Link } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./storage/mspStore";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));

/**
 * This page will be render if the request from different origin
 * @returns 
 */
const UnsecuredPage = () => (
  <div>
    <h2 className="error">If you see this webpage, the link you have clicked is under clickjacking security attack.</h2>
    {/* <h2>Please inform team with the reference of the application from where you clicked this link.</h2> */}
    <h2>Click <Link href={window.self.location.href} title='Web Application' target='blank'>here</Link> to access webpage safely.</h2>
  </div>
);

/**
 * Main application initilization
 */
root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
