import React from 'react'
import ResultTableHeader from './ResultTableHeader'
import './resultTable.css'
import { numberWithCommas } from '../../../common/lib/helper'
import equal from './png-clipart-equals-sign-equality-computer-icons-equals-rectangle-black-thumbnail.png'

function ResultTableDiv({ data,screen }) {

    const renderFieldsKey = (flag) => {
        if(data && data.length > 0){
            return data.map((item,index) => {
                const {name, aclar, unit,cff, annualSavingsTons, annualSavingsPercentage,} = item
                let style = {}
                if (index >= (data.length - 1)) {
                    style = {
                        fontWeight: 'bold'
                    }
                }
                if(flag === 'name'){
                    return <span className='l1' style={style}>{name}</span>
                }else if(flag === 'aclar'){
                    return <span className='l2' style={style}><span-value style={{ color: "#DC202E" }}>{numberWithCommas(aclar)}</span-value> {unit}</span>
                }else if(flag === 'cff'){
                    return <span className='l2' style={style}><span-value style={{ color: "#DC202E" }}>{numberWithCommas(cff)}</span-value> {unit}</span>
                }else if(flag === 'annual'){
                    return <span className='l2'><span-value style={{ color: "#DC202E" }}>{numberWithCommas(annualSavingsTons)}</span-value> <unit>{unit}</unit> or <span-value style={{ color: "#DC202E" }}>{annualSavingsPercentage}</span-value><unit>%</unit></span>
                }
            })
        }
    }

    const renderFieldsKeyV2 = (flag, item, index) => {
        if(item){
        
            const {name, aclar, unit,cff, annualSavingsTons, annualSavingsPercentage,} = item
            let style = {}
            if (index >= (data.length - 1)) {
                style = {
                    fontWeight: 'bold'
                }
            }
            if(flag === 'name'){
                return <span className='l1' style={style}>{name}</span>
            }else if(flag === 'aclar'){
                return <span className='l2' style={style}><span-value style={{ color: "#DC202E" }}>{numberWithCommas(aclar)}</span-value> {unit}</span>
            }else if(flag === 'cff'){
                return <span className='l2' style={style}><span-value style={{ color: "#DC202E" }}>{numberWithCommas(cff)}</span-value> {unit}</span>
            }else if(flag === 'annual'){
                return <span className='l2'><span-value style={{ color: "#DC202E" }}>{numberWithCommas(annualSavingsTons)}</span-value> <unit>{unit}</unit> or <span-value style={{ color: "#DC202E" }}>{annualSavingsPercentage}</span-value><unit>%</unit></span>
            }
            
        }
    }

    const renderRow = () => {
        if(data && data.length > 0){
            let displayOnce = false;
            let vsStyle = { color: "#DC202E", fontWeight: 900, fontSize: "20px", paddingTop: "0px" };
            return data.map((item,index) => {
                if(index === 1 && data.length === 3){
                    displayOnce = true;
                    vsStyle.paddingTop = "0px";
                }
                else if(index === 1 && data.length > 3){
                    displayOnce = true;
                    vsStyle.paddingTop = "15px";
                }else if(data.length === 2 && index === 0){
                    displayOnce = true;
                    vsStyle.paddingTop = "15px";
                }else{
                    displayOnce = false;
                }
                return <div className='row data-result-output '>
                    <div className="col-3 name" style={{ height:"auto", paddingLeft: "38px",  textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                        {renderFieldsKeyV2('name',item,index)}
                    </div>
                    <div className="col-2" style={{  paddingLeft: "5%", textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                        {renderFieldsKeyV2('aclar',item,index)}
                    </div>
                    <div className="col-1" style={vsStyle}>{displayOnce ? 'VS.':''}</div>
                    <div className="col-2" style={{  paddingLeft: "5%", textAlign: "left", font: "normal normal medium 20px/30px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                        {renderFieldsKeyV2('cff',item,index)}
                    </div>
                    <div className="col-1 equal" style={vsStyle}>{displayOnce ? <img style={{height: '25px'}} src={equal}/>:''}</div>
                    <div className="col-3 ann" style={{ backgroundColor: "#DDDDDD", textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000", fontWeight: "bold" }}>
                        {renderFieldsKeyV2('annual',item,index)}
                    </div>
                </div>
            })    
        }
        
    }

    return (
        <div className={'result-table-statistics '+(screen?screen:'')} style={{borderBottom: "1px solid white", zIndex:"999"}}>
            <ResultTableHeader />   
            {renderRow()}
            {/* <div className="row data-result-output d-flex align-items-center">
                <div className="col-3 name" style={{  paddingLeft: "38px", paddingTop:"10px", textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                    {renderFieldsKey('name')}
                </div>
                <div className="col-2" style={{  paddingLeft: "5%",paddingTop:"10px", textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                    {renderFieldsKey('aclar')}
                </div>
                <div className="col-1" style={{ textAlign:"center", color: "#DC202E", fontWeight: 900, fontSize: "20px" }}>VS.</div>
                <div className="col-2" style={{  paddingLeft: "5%",paddingTop:"10px", textAlign: "left", font: "normal normal medium 20px/30px Honeywell Sans", letterSpacing: "0px", color: "#000000" }}>
                    {renderFieldsKey('cff')}
                </div>
                <div className="col-1" style={{   color: "#DC202E", fontWeight: "bold", fontSize: "30px", fontWeight: 900 }}>=</div>
                <div className="col-3 ann" style={{ backgroundColor: "#DDDDDD",paddingTop:"10px", textAlign: "left", font: "normal normal medium 25px/35px Honeywell Sans", letterSpacing: "0px", color: "#000000", fontWeight: "bold" }}>
                    {renderFieldsKey('annual')}
                </div>
            </div> */}
        </div>
    )
}

export default ResultTableDiv;