import { render } from "@testing-library/react";
import React, { Component } from "react";
import ResultTableDiv from "../common_result_table/ResultTableDiv";
import "./cardboard.css";
import CardBoardImage from '../../../../assets/image/Desktop/Trees.png'
import videoURLCardBoard from '../../../../assets/video/waste-video.ogg';
import videoCardBoardMov from '../../../../assets/video/wastevideo.mov'
import ArrowImage from '../../../../assets/image/Desktop/down.png'


function Cardboard({ Calculation,SubHeadingValue,subHeadingUnit }) {
  return (
    <div className="mainContainer6 ">
      <div className="row ">
        <div className="col d-sm-block video3">
        <video id="background-video-cardboard"  loop autoPlay muted playsInline>
          <source src="/stream-video?video_name=projectboard.m4v" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
      <div className="main5 row">
        <div className="col-8 cardboard-heading" style={{ textAlign: "left" }}>
          <h1 >Reduce primary and  <br/>secondary waste.²</h1>
          
          <h2 style={{paddingTop:"2%"}}>Save  <a style={{ color: "#DC202E", fontWeight: "bold" }}>{SubHeadingValue}{subHeadingUnit} of the trees</a> 
          <br/>required to make cardboard.</h2>
        
          <h4 style={{ fontSize: "18px", lineHeight:1}}>
            Smaller packs means less<br />
            cardboard. It’s that simple.<br />
            And when you use less cardboard,<br />
            you reduce the number of trees and<br />
            the amount of water required to<br />
            manufacture that cardboard.
          </h4>
          <small className="card-sml">² Based on cartons and shippers used in the <strong>Aclar®</strong> packaging system as compared to CFF.</small>
        </div>
       
        <div className="col-4 right-side-image-cardboard" style={{ textAlign: "center" }}>
             
                <img src={CardBoardImage} className="image"/>
          {/* <img src={shippingContainerGif}  style={{ width: "61%", paddingTop: "25%", paddingLeft: "9%" }} /> */}
        </div>
      </div>
      <div className="row" style={{paddingBottom:"5%"}}>
        <div className="col">
          <ResultTableDiv data={Calculation} screen={'screen-4'}/>
        </div>
      </div>
      <div className="circle-arrow arrow-top">   
        <img src={ArrowImage} alt="down arrow"/>
      </div>
      
    </div>
  )
}

export default Cardboard;
